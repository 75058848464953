// config.js
module.exports = {
  siteURL: "https://admin.gallosmarmol.com.pe",
  app: {
    apiKey: "AIzaSyDy_MDrKSCTP45VzEeE4rZhZmgeB4J593I",
    authDomain: "https://admin.gallosmarmol.com.pe",
    databaseURL: "https://gallosmarmol.firebaseio.com",
    projectId: "gallosmarmol",
    storageBucket: "gallosmarmol.appspot.com",
    messagingSenderId: "887968983395",
    appId: "1:887968983395:web:cc354f44b6317a96030b56",
  },
  tokens: [
    "zJAF74hs1cYy51tf4Ht6kssRSSTG9bhenLmmutXINQdoojfNRZ/6vlK+W8H57QR2AFab4uLH1p2q8XNLUQxtCg==",
  ],
  gtm_id: "GTM-NGWNDF7",
  statitics_url:
    "https://datastudio.google.com/embed/reporting/1SU47t_-wTBLqnnV-QY8SWhCo0k-s0tD9/page/RMkQB",
  report_activities_url:
    "https://datastudio.google.com/embed/reporting/875afff9-e6d7-4669-90ca-69863191e445/page/nYLqB",
  fcm_key:
    "BCRV92WJ6LZ-UB7Ur8lp2jA7YM_AyHhitpITAFDf-Nb_rybTnJgHKhz-jok7iUg05xAkT9zOgkIwTfv6VAEfIqo",
  algolia: {
    id: "N2ZGDM4H17",
    key: "cf079cf68757e02ac66d829dee13296b",
    admin_key: "adc9d6d6b1282fec3c0140e4b2392749",
  },
  whatsappServerURL: "",
  hideOriginForAdvisors: false,
  allowAllocationSameAgent: false,
  allowAllocationByPortion: false,
  contactImportData: [
    { header: "Nombres", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "País", key: "country", width: 20, type: "string" },
    { header: "Ciudad", key: "city", width: 20, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    {
      header: "Correo del Encargado",
      key: "agent_id",
      width: 20,
      type: "agent",
    },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  contactExportData: [
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "País", key: "country", type: "string", width: 20 },
    { header: "Ciudad", key: "city", type: "string", width: 20 },
    { header: "Estado", key: "state", type: "string", width: 20 },
    {
      header: "Materiales interesados",
      key: "interested_materials",
      type: "array",
      width: 20,
    },
    { header: "Comentario", key: "comment", type: "string", width: 20 },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    {
      header: "¿Aceptó términos y condiciones?",
      key: "terms",
      type: "terms",
      width: 20,
    },
    {
      header: "Última actividad",
      key: "last_comment",
      type: "string",
      width: 20,
    },
    {
      header: "Fecha de última actividad",
      key: "last_activity",
      type: "timestamp",
      width: 20,
    },
    { header: "Total compra", key: "total_value", type: "number", width: 10 },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
    { header: "Origen", key: "origin", type: "string", width: 20 },
    {
      header: "Encargado que registró el contacto",
      key: "created_uid",
      type: "agent",
      width: 20,
    },
  ],
  contactFilters: {
    country: { id: "country", name: "País", primary: true },
    agent_id: {
      id: "agent_id",
      name: "Agente",
      primary: true,
      readonly_advisor: true,
      show_attr: "full_name",
    },
  },
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) ||
          "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    role: {
      type: "select",
      label: "Rol",
      items: [
        "Arquitecto",
        "Persona Natural",
        "Diseñador de Interiores",
        "Contratista",
      ],
      placeholder: "Rol",
      flex: { xs: 12, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: {
      type: "text",
      label: "Comentario",
      placeholder: "Comente aquí",
      flex: { xs: 12 },
      show_on_detail: true,
    },
    agent: {
      type: "select",
      label: "Encargado",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
    },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones de Gallos Mármol",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  tableContact: [
    { text: "Contacto", align: "center", sortable: false, value: "full_name" },
    { text: "Email", align: "center", sortable: false, value: "email" },
    { text: "País", align: "center", sortable: false, value: "country" },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    { text: "Encargado", align: "center", sortable: false, value: "agent_id" },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  contactActivityImportData: [
    {
      header: "Correo electrónico",
      key: "contact_email",
      width: 20,
      type: "email",
    },
    {
      header: "Tipo de actividad",
      key: "activity_type",
      width: 20,
      type: "activity",
    },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  saleImportData: [
    { header: "Nombres*", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Dirección", key: "address", width: 20, type: "string" },
    { header: "Producto", key: "products", width: 20, type: "product" },
    { header: "Cantidad", key: "quantity", width: 20, type: "product_detail" },
    { header: "Precio", key: "price", width: 20, type: "product_detail" },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de Compra", key: "total_paid", width: 20, type: "number" },
    {
      header: "Método de pago (PAGO EXITOSO/PROCESANDO PAGO)",
      key: "payment_method",
      width: 20,
      type: "string",
    },
    {
      header: "Estado del pago",
      key: "paid_status",
      width: 20,
      type: "string",
    },
    {
      header: "Fecha de venta (dd/mm/yyyy)",
      key: "sales_date",
      width: 20,
      type: "date",
    },
    { header: "Vendedor", key: "seller", width: 20, type: "string" },
    {
      header: "Rubro (arquitecto, diseñador y otros)",
      key: "rol",
      width: 20,
      type: "string",
    },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  saleExportData: [
    { header: "Id", key: "id", type: "string", width: 30 },
    {
      header: "Nombres",
      key: "name",
      type: "string",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Apellidos",
      key: "last_name",
      type: "last_name",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Correo electrónico",
      key: "email",
      type: "email",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Teléfono",
      key: "phone",
      type: "string",
      width: 20,
      show_on_detail: true,
    },
    {
      header: "Dirección",
      key: "address",
      type: "string",
      width: 20,
      show_on_detail: true,
    },
    {
      header: "Producto/Cantidad/Precio",
      key: "products",
      type: "products",
      width: 30,
    },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de compra", key: "total_paid", type: "number", width: 20 },
    { header: "Estado", key: "state", type: "string", width: 20 },
    {
      header: "Método de pago",
      key: "payment_method",
      type: "string",
      width: 20,
    },
    { header: "Encargado", key: "created_uid", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
  ],
  orderFilters: {
    state: { id: "state", name: "Estado", primary: true },
    products_ids: { id: "products_ids", name: "Producto", primary: true },
  },
  tableTicket: [
    { text: "Contacto", align: "center", sortable: false, value: "full_name" },
    { text: "Estado", align: "center", sortable: false, value: "state" },
    {
      text: "Registrado",
      align: "center",
      sortable: false,
      value: "created_at",
    },
    {
      text: "Origen",
      align: "center",
      sortable: false,
      value: "origin",
      class: "w120",
    },
    {
      text: "Creador por",
      align: "center",
      sortable: false,
      value: "created_uid",
    },
    { text: "Encargado", align: "center", sortable: false, value: "agent_id" },
    { text: "Operación", align: "right", sortable: false, value: "operations" },
  ],
  ticketFilters: {
    state: { id: "state", name: "Estado", primary: true },
    origin: { id: "origin", name: "Origen", primary: true },
    agent_id: {
      id: "agent_id",
      name: "Agente",
      primary: true,
      readonly_advisor: true,
      item_text: "full_name",
    },
  },
  ticketFormSchema: {
    ticket_name: {
      type: "text",
      label: "Nombre del ticket",
      placeholder: "Nombre del ticket",
      flex: { xs: 12 },
      show_on_detail: true,
      form_update: true,
    },
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          !v ||
          (!!v &&
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            )) ||
          "Correo electrónico no válido",
      ],
      show_on_detail: true,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) ||
          "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    role: {
      type: "select",
      label: "Rol",
      items: [
        "Arquitecto",
        "Persona Natural",
        "Diseñador de Interiores",
        "Contratista",
      ],
      placeholder: "Rol",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    company: {
      type: "text",
      label: "Empresa",
      placeholder: "Empresa",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    gallos_project: {
      type: "text",
      label: "Proyecto del cliente",
      placeholder: "Proyecto del cliente",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    to_remodel: {
      type: "select",
      label: "¿Cuándo tiene planeada la remodelación o construcción?",
      items: ["0 a 6 meses", "6 meses a más"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    interested_materials: {
      type: "autocomplete",
      data_type: "array",
      label: "Materiales de interés",
      placeholder: "Seleccione",
      items: [
        "Cuarzo",
        "Granito",
        "Mármol",
        "Cerámico",
        "Madera",
        "Terrazo",
        "Porcelanato",
        "Mosaicos de diseño",
        "Losetas de concreto",
        "Rococho",
        "Talamolle",
        "Catálogo",
      ],
      flex: { xs: 12, sm: 6 },
      multiple: true,
      show_on_detail: true,
      form_update: true,
    },
    comment: {
      type: "text",
      label: "Comentario",
      placeholder: "Comente aquí",
      flex: { xs: 12 },
      show_on_detail: true,
    },
    medium_contact: {
      type: "select",
      label: "Medio de contacto",
      items: [
        "Whatsapp",
        "Facebook",
        "Instagram",
        "Web",
        "Llamada",
        "Correo",
        "Sala de ventas",
        "Consulta",
        "Evento",
      ],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    know_gallosmarmol: {
      type: "select",
      label: "Cómo conociste Gallos",
      items: [
        "redes sociales",
        "web",
        "recomendación",
        "pasé cerca a la tienda",
        "otros",
      ],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    store: {
      type: "select",
      label: "Tienda",
      items: ["Miraflores", "La Molina", "Lurín", "Surco"],
      placeholder: "Tienda",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    origin: {
      type: "autocomplete",
      label: "Origen",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
    },
    agent: {
      type: "select",
      label: "Encargado",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
    },
    tags: {
      type: "hidden",
      data_type: "tags",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
    terms: {
      type: "hidden",
      data_type: "boolean",
      label: "Términos y condiciones",
      flex: { xs: 12 },
    },
  },
  ticketImportData: [
    {
      header: "Nombre del Ticket",
      key: "ticket_name",
      type: "string",
      width: 30,
    },
    {
      header: "Nombres*",
      key: "name",
      width: 30,
      type: "string",
      validate: true,
      required: true,
    },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Rol", key: "role", type: "string", width: 20 },
    { header: "Estado", key: "state", type: "string", width: 20 },
    {
      header: "Materiales interesados",
      key: "interested_materials",
      type: "array",
      width: 20,
    },
    { header: "Tienda", key: "store", type: "string", width: 20 },
    {
      header: "Cómo conociste Gallos",
      key: "know_gallosmarmol",
      type: "string",
      width: 20,
    },
    { header: "Empresa", key: "company", type: "string", width: 20 },
    {
      header: "Proyecto del cliente",
      key: "gallos_project",
      type: "string",
      width: 20,
    },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    {
      header: "Correo del Encargado",
      key: "agent_id",
      width: 20,
      type: "agent",
      validate: true,
    },
    {
      header: "Términos y condiciones (si/no)",
      key: "terms",
      width: 20,
      type: "boolean",
    },
    {
      header: "Origen*",
      key: "origin",
      width: 20,
      type: "string",
      validate: true,
      required: true,
    },
  ],
  ticketExportData: [
    { header: "Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Rol", key: "role", type: "string", width: 20 },
    { header: "País", key: "country", type: "string", width: 20 },
    { header: "Departamento", key: "city", type: "string", width: 20 },
    { header: "Distrito", key: "district", type: "string", width: 20 },
    {
      header: "¿Cuándo tiene planeada la remodelación o contrucción?",
      key: "to_remodel",
      type: "string",
      width: 30,
    },
    {
      header: "Materiales interesados",
      key: "interested_materials",
      type: "array",
      width: 20,
    },
    { header: "Tienda", key: "store", type: "string", width: 20 },
    {
      header: "Cómo conociste Gallos",
      key: "know_gallosmarmol",
      type: "string",
      width: 20,
    },
    { header: "Empresa", key: "company", type: "string", width: 20 },
    {
      header: "Proyecto del cliente",
      key: "gallos_project",
      type: "string",
      width: 20,
    },
    {
      header: "¿Desea recibir ofertas y promociones?",
      key: "newsletter",
      type: "boolean",
      width: 20,
    },
    {
      header: "¿Términos y condiciones aceptados?",
      key: "terms",
      type: "boolean",
      width: 20,
    },
    { header: "Estado", key: "state", type: "state", width: 20 },
    { header: "Comentario", key: "comment", type: "string", width: 20 },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    {
      header: "Última actividad",
      key: "last_comment",
      type: "string",
      width: 20,
    },
    {
      header: "Fecha de última actividad",
      key: "last_activity",
      type: "timestamp",
      width: 20,
    },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
    { header: "Origen", key: "origin", type: "string", width: 20 },
    {
      header: "Encargado que registró el ticket",
      key: "created_uid",
      type: "agent",
      width: 20,
    },
  ],
  ticketActivityImportData: [
    { header: "ID del Ticket", key: "ticket_id", width: 20, type: "string" },
    {
      header: "Tipo de actividad",
      key: "activity_type",
      width: 20,
      type: "activity",
    },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  orderFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: ["required"],
      order: 2,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      order: 3,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      order: 4,
    },
    phone_secondary: {
      type: "text",
      label: "Teléfono convencional",
      placeholder: "Teléfono convencional",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      first_word: "Teléfono convencional: ",
      show_on_landing: true,
      order: 5,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: ["required", "email"],
      order: 6,
      searchable: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      items: ["DNI", "Carnet de extranjería"],
      placeholder: "Seleccione",
      flex: { sm: 6 },
      order: 7,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { sm: 6 },
      order: 7,
      searchable: true,
    },
    origin: {
      type: "autocomplete",
      label: "Origen",
      items: [
        "whatsApp",
        "facebook",
        "instagram",
        "twitter",
        "linkedIn",
        "tienda",
      ],
      placeholder: "Seleccione",
      rules: ["required"],
      flex: { xs: 12, sm: 6 },
      order: 9,
    },
  },
  agentTable: [
    { text: "Encargado", align: "left", value: "full_name" },
    { text: "Correo electrónico", align: "center", value: "email" },
    { text: "Rol", align: "center", sortable: false, value: "role" },
    {
      text: "Equipo",
      align: "center",
      value: "team",
    },
    {
      text: "Proyectos",
      align: "center",
      value: "projects",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "active",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  agentFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
  },
  theme: {
    // dark: true,
    themes: {
      light: {
        primary: "#cb3e2c",
        secondary: "#000000",
        accent: "#607d8b",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
      dark: {
        primary: "#cb3e2c",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
};
